document.addEventListener('DOMContentLoaded', function () {
    var offcanvasMobile = window.matchMedia("(max-width: 1020px)");
    if(offcanvasMobile.matches) {

        var slideout = new Slideout({
            'panel': document.getElementById('offcanvas__panel'),
            'menu': document.getElementById('offcanvas__menu'),
            'side': 'right',
            'touch': false
        });

        // add classchange Behaviour to burger icon
        function addEventListenerByClass(className, event, fn) {
            var list = document.getElementsByClassName(className);
            for (var i = 0, len = list.length; i < len; i++) {
                list[i].addEventListener('click', function() {
                    this.classList.toggle('is-active');
                    slideout.toggle();
                    console.log('clicked');
                });
            }
        }

        addEventListenerByClass('hamburger');

    }
});